@use "@angular/material" as mat;
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";

// Fonts
$app-typography: mat.m2-define-typography-config(
    $font-family: $font-family,
);

$light-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn,
        ),
        typography: $app-typography,
    )
);

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

@include mat.all-component-themes($light-theme);

@import "abstracts/mixins";
@import "base/reset";
@import "base/utility";
@import "layout/page";
@import "layout/header";
@import "layout/sidenav";
@import "layout/card";
@import "layout/table";
@import "layout/form";
@import "layout/dialogs";
@import "layout/buttons";
@import "layout/filter";
@import "layout/pem";
@import "layout/comments";
@import "layout/messages";
@import "layout/alerts";
@import "layout/slide-toggle";
@import "layout/snackbar";
@import "layout/expansion_panel";
@import "layout/logout";

.mat-mdc-dialog-title {
    .dialog-instructions {
        font-size: 14px;
        text-transform: none;
        line-height: 18px;
        color: #555;
    }
}
.edit-dialog {
    max-width: 100vw !important;
    width: 100%;
    height: 100%;
}
.mat-mdc-dialog-container {
    margin: 10px 0;
    button {
        span {
            text-transform: uppercase;
        }
    }
    mat-form-field {
        width: 100%;
    }
}
.mat-mdc-dialog-container .mdc-dialog__content {
    color: $dark-primary-text !important;
}

@media only screen and (min-width: 601px) {
    .edit-dialog {
        max-height: initial;
        height: auto;
        width: 400px;
    }
}

.mat-mdc-card {
    padding: 15px;

    &.no-padding {
        padding: 0;

        .section-header {
            margin-top: 0;
        }

        .card-header {
            margin: 0;
            padding: 15px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }
        }
    }
    &.no-edge-padding {
        padding-left: 0;
        padding-right: 0;

        .section-header {
            margin-top: -15px;
        }
    }
    &.table-wrapper {
        padding: 0;
    }
    .card-header {
        margin: 0 -15px 15px;
        padding: 0 15px 15px;

        h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }

        h5 {
            margin: 5px 0 0;
            font-size: 14px;
            font-weight: normal;
            color: $dark-accent-text;
        }

        &.no-bottom-margin {
            margin-bottom: 0;
        }

        &.no-border {
        }
    }
    .section-header {
        padding-left: 15px;
        padding-right: 15px;
    }

    .collapsible-section {
        .collapsible-section-header {
            border-top: 1px solid #d0d0d0;
            padding-left: 15px;
            padding-right: 15px;
            margin-left: -15px;
            margin-right: -15px;
        }
        .collapsible-section-content {
            padding: 15px;
        }
    }
}

.section-filter {
    padding: 10px 0;
}

mat-card.titled-card {
    margin-top: 7.5px;
    margin-bottom: 7.5px;

    &.disabled {
        background-color: #f8f8f8;
        opacity: 0.85;
    }

    .mat-mdc-card-title {
        font-size: 16px;
        font-weight: 600;
        height: 40px;
        h4 {
            cursor: pointer;
        }
    }
    .mat-divider {
        margin-left: -15px;
        margin-right: -15px;
    }
    .details {
        padding-top: 20px;

        button.icon-button {
            font-size: 18px;
            margin-right: -10px;

            .ng-fa-icon {
                margin-right: 5px;
            }
        }

        .section {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 10px;
        }
    }
}

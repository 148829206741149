.form-field {
    padding: 10px 0;

    .mat-mdc-form-field {
        .csr-field {
            font-size: 12px;
            line-height: 20px;
            max-height: 100px;
            height: 100px;
        }
    }
}
.form-action-buttons {
    padding-top: 30px !important;
}
.mat-mdc-button.mat-small {
    min-width: 30px;
    width: 30px;
    height: 20px;
    line-height: 20px;
    min-height: 20px;
    vertical-align: top;
    font-size: 16px;
    padding: 0 0;
    margin: 0;

    i {
        font-size: 16px;
    }
}
button,
.mdc-button,
a.mat-mdc-button,
a.mat-mdc-button-base {
    text-transform: uppercase !important;
}
.mat-mdc-form-field-hint {
    font-size: 13px !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none !important;
}
.mat-icon.toggle-visibility {
    font-size: 18px !important;
    width: 1.25em !important;
    height: 18px !important;
}

$sm-width: 600px;
$md-width: 960px;
$lg-width: 1280px;
$xl-width: 1920px;

@mixin breakpoint-sm {
    @media (screen and (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px})) {
        @content;
    }
}
@mixin breakpoint-md {
    @media (screen and (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px})) {
        @content;
    }
}
@mixin breakpoint-lg {
    @media (screen and (min-width: #{$lg-width}) and (max-width: #{$xl-width - 1px})) {
        @content;
    }
}
@mixin breakpoint-xl {
    @media (screen and (min-width: #{$xl-width})) {
        @content;
    }
}
@mixin gt-xs {
    @media (min-width: #{$sm-width}) {
        @content;
    }
}
@mixin gt-sm {
    @media (min-width: #{$md-width}) {
        @content;
    }
}
@mixin gt-md {
    @media (min-width: #{$lg-width}) {
        @content;
    }
}

@mixin profile-info {
    .profile-info {
        padding-left: 10px;
        color: $dark-accent-text;
        overflow: hidden;

        h4,
        h5 {
            font-weight: normal;
            text-overflow: ellipsis;
            word-break: break-all;
            word-wrap: break-word;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            width: 150px;
        }

        h4 {
            font-size: 0.95em;
            margin: 0 0 5px 0;
            text-transform: capitalize;
        }

        h5 {
            font-size: 0.8em;
            margin: 0;
        }
    }
}

@mixin profile-picture {
    .profile-picture {
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }
}

@mixin profile {
    .profile {
        margin-left: 10px;

        @include profile-picture;
        @include profile-info;
    }
}

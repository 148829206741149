.page-wrapper.logout-wrapper {
    background: linear-gradient(
        to bottom,
        $header-background-color 0px,
        $header-background-color 250px,
        $header-background-color 250px,
        #f5f7fa 250px,
        #f5f7fa 100%
    );
    padding-top: 75px;

    h1 {
        background: url($logo_png) no-repeat center top;
        background-image: url($logo_svg);
        background-size: 120px;

        color: $light-text;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        padding-top: 70px;
    }

    .mat-mdc-card {
        width: 75%;
        margin: 0 auto;

        .mat-mdc-card-content {
            height: 100px;
            color: $dark-accent-text;
        }
    }
}

@include gt-md {
    .page-wrapper.logout-wrapper {
        .mat-mdc-card {
            width: 50%;
        }
    }
}

.view {
    width: 100%;

    .page-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .page-header {
        display: flex;
        flex: 0;
        min-height: 70px;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        box-sizing: border-box;

        background-color: $subheader-background-color;
        border-bottom: 1px solid $subheader-border-color;

        h2 {
            font-size: 22px;
            font-weight: 400;
            color: $dark-primary-text;
        }

        &.with-back-button {
            button.back-button {
                margin-left: -10px;
                font-size: 20px;
                color: #c0c0c0;
                min-width: 40px;
            }
        }
    }
    .page-content {
        display: flex;
        flex-direction: column;
        flex: none;

        padding: 20px;
        overflow: auto;
    }
}

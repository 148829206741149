// Text Colors
$dark-text: #333;
$dark-primary-text: rgba($dark-text, 0.9);
$dark-accent-text: rgba($dark-primary-text, 0.75);
$dark-disabled-text: rgba($dark-primary-text, 0.4);

$light-text: #fff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);

$danger-main: #c00426;
$danger-lighter: #ecb4be;
$danger-darker: #aa0217;

$warn-main: #ff5722;
$warn-lighter: #f5a58b;
$warn-darker: #cd4116;

$success-main: #14c345;
$success-lighter: #b9edc7;
$success-darker: #0bae2e;

// Background Colors
$app-background-color: #f5f7fa;

// Background Size
$app-background-size: 135px 30px;

// Header padding-left
$header-padding-left: 145px;

// Header Background position
$header-background-position: left center;

// Header Colors
$header-background-color: rgb(0, 83, 155);
$header-color: rgba(255, 255, 255, 0.56);
$header-menu-hover-background-color: #002f54;
$header-menu-hover-color: #fff;

// Sub-header Colors
$subheader-background-color: #fafbfc;
$subheader-color: $dark-text;
$subheader-border-color: #e0e0e0;

// Navigation Colors
// Sidenav Colors
$sidenav-close: rgba($dark-text, 0.75);
$sidenav-separator: rgba($dark-text, 0.05);
$sidenav-item-background-color: transparent;
$sidenav-item-hover-background-color: #f9f9f9;
$sidenav-item-border-color: transparent;
$sidenav-item-active-border-color: $primary-main;

// Menu Colors
$menu-separator: rgba($dark-text, 0.05);

// Table Colors
$table-row-hover: rgba(220, 242, 253, 1);

// Loader Colors
$loader-overlay-color: transparent;
$loader-color: rgba($primary-main, 0.5);

// Filters
$filter-chip-background-color: #dbe8f1;
$filter-chip-color: #2c3a47;

// Table Row
$row-stripe-background-color: #fafafa;
$row-alert-error-color: $danger-main;

// Logo
$image-path: "/assets/images";

// Comments
$comment-color: #333;
$comment-date-color: #666;
$comment-you-color: #3455b3;
$comment-background-color: #fbfbfb;
$comment-you-background-color: #fff;

// Alerts
$alert-info-color: #856404;
$alert-info-background-color: #fff3cd;

// Snackbar outline colors
$info-border-color: #00b0ec;
$error-border-color: #d70044;
$success-border-color: #96bb3b;

$logo_png: "../../images/logo.png";
$logo_svg: "../../images/logo.svg";

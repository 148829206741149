.alert {
    &.info {
        padding: 10px 15px;
        font-size: 14px;
        background-color: $alert-info-background-color;
        border-radius: 4px;
        color: $alert-info-color;
        margin: 5px 0;
    }

    .mat-mdc-outlined-button {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@import "variables";

.success-snackbar {
    background-color: $success-main !important;
    color: #fff !important;
}

.warning-snackbar {
    background-color: $warn-main !important;
    color: #fff !important;
}

.danger-snackbar {
    background-color: $danger-main !important;
    color: #fff !important;
}

.mat-mdc-dialog-content {
    .message {
        margin-bottom: 15px;
    }
}
.message {
    padding: 15px 10px;
    border-radius: 4px;
    position: relative;

    &.with-table {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.success {
        color: #fff;
        background-color: $success-main !important;
    }

    &.warning {
        color: #fff;
        background-color: $warn-main !important;
    }

    &.danger {
        color: #fff;
        background-color: $danger-main;
    }
}

@import "variables";

.filter-drawer {
    width: 340px;
    padding: 0 15px;

    .filter-header {
        padding: 10px 0;

        h2 {
            font-size: 20px;
            font-weight: 400;
            color: $dark-primary-text;
        }

        .close-drawer {
            font-size: 22px;
            line-height: 20px;
        }
    }
}
.filter-values {
    .filter-chip {
        background-color: $filter-chip-background-color;
        color: $filter-chip-color;
        max-width: 250px;
        border-radius: 4px;
        padding: 5px;
        margin: 0 3px 5px;

        label {
            font-style: italic;
            text-transform: capitalize;
            font-size: 11px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        div {
            font-size: 13px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        button {
            padding: 5px;
            margin-left: 10px;
            min-width: 25px;
            line-height: 20px;
            font-size: 16px;
        }
    }
}

@include gt-xs {
    .filter-values {
        .filter-chip {
            margin: 0 3px 0;
        }
    }
}

.filter-icon {
    margin: 0px 10px 0px 5px;
}

header {
    height: 70px;
    align-items: center;
    justify-content: start;
    color: $header-color;
    padding: 0;
    margin: 0;

    @include gt-sm {
        padding: 0 15px;
    }

    background: url($logo_png) no-repeat center center $header-background-color;
    background-image: url($logo_svg);
    background-size: $app-background-size;

    h1 {
        font-weight: 300;
        font-size: 20px;

        a {
            color: $header-color;
            text-decoration: none;

            @include gt-sm {
                padding-left: $header-padding-left;
            }
        }
    }

    button {
        font-size: 18px !important;
        height: 70px !important;
        width: 70px !important;
        border: 3px solid $header-background-color;
        border-radius: 0 !important;
        color: $header-color !important;

        &:hover {
            background-color: $header-menu-hover-background-color !important;
            color: $header-menu-hover-color !important;
            border-color: $header-menu-hover-background-color !important;
        }
        &:focus {
            border-color: $header-menu-hover-color !important;
        }
    }

    @include gt-sm {
        background: url($logo_png) no-repeat $header-background-position $header-background-color;
        background-image: url($logo_svg);
        background-size: $app-background-size;

        padding: 0 20px;
    }
}

.profile-menu {
    min-width: 220px !important;

    @include profile;

    .profile {
        border-bottom: 1px solid $menu-separator;
        padding: 10px 10px;
        margin: 0 0 10px 0;

        .profile-picture {
            height: 40px;
            width: 40px;
        }
    }
    .mat-mdc-menu-item {
        .ng-fa-icon {
            margin-right: 5px;
        }
    }
    .mat-mdc-menu-content {
        .logout {
            margin-top: 5px;

            .mdc-list-item__primary-text {
                width: 100% !important;

                button {
                    width: 100% !important;
                }
            }
        }
    }
}

@import "variables";

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.clickable {
    cursor: pointer;
}
.hoverable {
    &:hover {
        background-color: $table-row-hover;
    }
}
.text-success {
    color: $accent-main !important;
}
.text-warning {
    color: $warn-main !important;
}
.text-danger {
    color: $danger-main !important;
}
.layout-padding {
    padding: 15px;
}
.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.stacked-label {
    label {
        display: block;
        font-size: 14px;
        color: #666;
        margin-bottom: 5px;
    }

    &.with-button {
        button {
            margin-top: -6px;
        }
    }

    word-break: break-all;
}
ul.list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 0 0 5px 0;
    }
}
.capitalize {
    text-transform: capitalize;
}
.full-width {
    width: 100%;
}
.hint {
    font-size: 13px;
    color: rgba(128, 128, 128, 1);
}

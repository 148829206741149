.pem {
    height: 150px;
    overflow: auto;
    word-wrap: break-word;
    background-color: #fafafa;
    padding: 10px 15px;
    font-family: monospace !important;
    font-size: 14px;
    line-height: 18px;

    &.expand {
        height: auto;
    }
}
.pem-wrapper {
    > div {
        margin-bottom: -15px;
    }
    .ng-fa-icon {
        font-size: 18px;
    }
}

.mat-accordion {
    .mat-expansion-panel {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .mat-expansion-panel-header {
            height: 60px;

            h4 {
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: 600;
            }
        }

        &.mat-expanded {
            border-bottom: 0;

            .mat-expansion-panel-header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .mat-mdc-card {
            &.embedded {
                border: 0;
            }
        }
    }

    &.mat-card-container {
        .mat-expansion-panel {
            margin-left: -15px;
            margin-right: -15px;
        }

        &.embedded {
            .mat-expansion-panel {
                box-shadow: none;
                border-radius: 0;
            }
            .mat-expansion-panel.mat-expanded {
                .mat-expansion-panel-header {
                    border-bottom: 0;
                }
            }

            .mat-expansion-panel-header {
                border-top: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
    }
}

html,
body {
    height: 100%;
    min-height: 100%;

    -webkit-tap-highlight-color: transparent;
}
html,
body * {
    font-family: $font-family !important;
}
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: $font-family !important;
    background-color: $app-background-color;
}
input,
select,
textarea,
label,
button {
    font-family: $font-family, sans-serif !important;
}
section {
    padding: 20px;
}
.loading-screen {
    position: absolute;
    background-color: rgba($dark-text, 0.1);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.mat-mdc-row:nth-child(even) {
    background-color: $row-stripe-background-color;
}
.table-content-header {
    padding: 0 20px;
    margin: 0;
}
.table-content-footer {
    padding: 15px 20px;
}

.table-content {
    max-width: 100%;
    overflow: auto;
}

.table-cell-align-right {
    text-align: right;
    justify-content: flex-end;

    &.mat-mdc-header-cell {
        text-align: right;
        justify-content: flex-end;
    }

    .mat-sort-header-container {
        justify-content: flex-end !important;
    }
}

table.mat-mdc-table {
    box-shadow: none;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &.with-filter,
    &.with-message {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    tr {
        &.alert-error {
            td {
                color: $row-alert-error-color;
                font-weight: 500;
            }
        }

        td,
        td.mat-mdc-cell {
            padding: 0 8px;

            &:first-child {
                padding-left: 24px;
            }
            &:last-child {
                padding-right: 24px;
            }
        }
    }

    thead {
        tr {
            th {
                font-weight: 500;
                line-height: 40px;
                font-size: 14px;
                color: $dark-accent-text;

                &:first-child {
                    border-top-left-radius: 6px;
                }

                &:last-child {
                    border-top-right-radius: 6px;
                }
            }
        }
    }

    th.mat-mdc-header-cell {
        text-transform: uppercase;
        padding: 0 8px;

        &:first-child {
            padding-left: 24px;
        }
        &:last-child {
            padding-right: 24px;
        }
    }

    &.resizable-columns {
        &.resizing {
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: col-resize;
        }

        .mat-mdc-cell {
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .mat-mdc-header-cell {
            position: relative;

            &:not(:last-child) {
                .resize-holder {
                    cursor: col-resize;
                    width: 20px;
                    height: 100%;
                    position: absolute;
                    right: -10px;
                    top: 0;
                    z-index: 1;
                }
            }
        }

        .mat-mdc-cell,
        .mat-mdc-header-cell {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    &:not(.resizable-columns) {
        th,
        td {
            min-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.no-ellipsis {
                text-overflow: initial;
            }

            &.icon {
                max-width: 50px;
                width: 20px;
            }
            &.small {
                max-width: 150px;
            }
            &.medium {
                max-width: 175px;
            }
            &.large {
                max-width: 200px;
            }
            &.xlarge {
                max-width: 250px;
            }
            &.xxlarge {
                max-width: 350px;
            }

            button.mat-mdc-icon-button {
                font-size: 20px;
            }
        }
    }
}

table.mat-calendar-table {
    th,
    td {
        min-width: auto;
    }
}
.no-data,
.mat-mdc-no-data-row td {
    background-color: #fff !important;
    padding: 20px !important;
}
tr.clickable {
    &:hover {
        background-color: $table-row-hover;
    }
}

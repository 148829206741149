.comment-row {
    font-size: 15px;
    color: $comment-color;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.1);
    background-color: $comment-background-color;

    .header {
        padding-bottom: 5px;

        .email {
            font-size: 14px;
            font-weight: bold;
            color: $comment-color;
        }

        .date {
            color: $comment-date-color;
            font-size: 12px;
            padding-left: 5px;
        }
    }

    &.you {
        color: $comment-you-color;
        background-color: $comment-you-background-color;

        .header {
            .email {
                color: $comment-you-color;
            }
        }
    }
}

.mat-mdc-unelevated-button {
    &.mat-caution {
        background-color: $override-main;
        color: $light-text;
    }
}
.mat-mdc-icon-button svg {
    width: 18px !important;
    height: 18px !important;
}
.mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 2px;
}
.mat-datepicker-toggle {
    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

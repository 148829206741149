.mat-mdc-snack-bar-container,
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: #fff !important;
    border: 1px solid $info-border-color;
    border-left-width: 4px;
    border-radius: 0 !important;
    color: $dark-primary-text !important;

    &.error {
        border: 1px solid $error-border-color;
    }

    &.success {
        border: 1px solid $success-border-color;
    }
}
.mat-mdc-snack-bar-label {
    color: $dark-primary-text !important;
}

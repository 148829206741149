.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) {
    --mdc-switch-selected-focus-state-layer-color: #{$bright-main} !important;
    --mdc-switch-selected-handle-color: #{$bright-main} !important;
    --mdc-switch-selected-hover-state-layer-color: #{$bright-main} !important;
    --mdc-switch-selected-pressed-state-layer-color: #{$bright-main} !important;
    --mdc-switch-selected-focus-handle-color: #{$bright-darker} !important;
    --mdc-switch-selected-hover-handle-color: #{$bright-darker} !important;
    --mdc-switch-selected-pressed-handle-color: #{$bright-darker} !important;
    --mdc-switch-selected-focus-track-color: #e0e0e0;
    --mdc-switch-selected-hover-track-color: #e0e0e0;
    --mdc-switch-selected-pressed-track-color: #e0e0e0;
    --mdc-switch-selected-track-color: #e0e0e0;
}

@import "variables";

.mat-sidenav {
    width: 300px;

    .mat-drawer-inner-container {
        overflow: hidden;
    }
    .mat-list-wrapper {
        overflow: auto;
    }

    @include gt-sm {
        width: 225px;
    }

    .profile-wrapper {
        min-height: 70px;
        border-bottom: 1px solid $sidenav-separator;

        @include profile;

        .close-menu {
            background-color: transparent;
            border: 0;
            min-height: 60px;
            min-width: 60px;
            outline: none;
            color: $sidenav-close;
        }
    }

    .mat-mdc-list-base {
        padding-top: 0;

        .mat-mdc-list-item.sidenav-menu {
            padding-left: 0;
            padding-right: 0;
            .mdc-list-item__content {
                margin: 0;
                padding: 0;

                a {
                    padding: 15px;
                    text-decoration: none;
                    color: $dark-accent-text;
                    font-size: 16px;
                    font-weight: 400;
                    width: 100%;
                    background-color: $sidenav-item-background-color;
                    border-right: 3px solid $sidenav-item-border-color;
                    transition: border-color 0.5s;
                    display: flex;
                    flex-direction: row;

                    &.active {
                        background-color: $sidenav-item-hover-background-color;
                        border-right: 3px solid $sidenav-item-active-border-color;
                        transition: border-color 0.5s;
                    }

                    &:hover {
                        background-color: $sidenav-item-hover-background-color;
                    }

                    span {
                        padding-left: 5px;
                    }
                }
            }

            &.sub-menu {
                .mdc-list-item__content {
                    a {
                        padding-left: 25px;
                    }
                }
            }
        }
    }
}

@use "@angular/material" as mat;

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600");
$font-family: "Roboto", "Helvetica Neue", "Helvetica", sans-serif;

// Theme Colors
$primary-main: #002e54;
$primary-lighter: #073860;
$primary-darker: #000022;

$accent-main: #999;
$accent-lighter: #aaa;
$accent-darker: #666;

$bright-main: rgb(148, 187, 32);
$bright-lighter: rgb(172, 211, 54);
$bright-darker: rgb(112, 143, 19);

$override-main: #96bb3c;
$override-lighter: #abd051;
$override-darker: #7b8f3e;

@import "base-variables";
@import "mixins";

// Overwrite base values here

// Header padding-left
$header-padding-left: 110px;

$warn-main: #d70044;
$warn-lighter: #d70c4c;
$warn-darker: #9f1f3a;

$primary-palette: (
    50: #e6ebf0,
    100: #beccdb,
    200: #94abc3,
    300: #6b8aab,
    400: #49719a,
    500: #1d5a8c,
    600: #135284,
    700: #054979,
    800: #003f6c,
    900: #002e54,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);
$accent-palette: (
    50: #f9f9f9,
    100: #f3f3f3,
    200: #ebebeb,
    300: #dcdcdc,
    400: #b8b8b8,
    500: #999,
    600: #707070,
    700: #5d5d5d,
    800: #3e3e3e,
    900: #3e3e3e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);
$warn-palette: (
    50: #fee3e8,
    100: #fbb8c5,
    200: #f88aa0,
    300: #f35a7a,
    400: #ed355f,
    500: #e70945,
    600: #d70044,
    700: #c20042,
    800: #af0040,
    900: #8d003c,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);

$theme-primary: mat.m2-define-palette($primary-palette, 900, 300, 700);
$theme-accent: mat.m2-define-palette($accent-palette, 600, 300, 700);
$theme-warn: mat.m2-define-palette($warn-palette);
